exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-our-commitment-js": () => import("./../../../src/pages/about/our-commitment.js" /* webpackChunkName: "component---src-pages-about-our-commitment-js" */),
  "component---src-pages-about-our-hope-js": () => import("./../../../src/pages/about/our-hope.js" /* webpackChunkName: "component---src-pages-about-our-hope-js" */),
  "component---src-pages-about-people-js": () => import("./../../../src/pages/about/people.js" /* webpackChunkName: "component---src-pages-about-people-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-climate-action-report-back-creek-js": () => import("./../../../src/pages/ClimateActionReport-BackCreek.js" /* webpackChunkName: "component---src-pages-climate-action-report-back-creek-js" */),
  "component---src-pages-climate-action-report-kanpur-js": () => import("./../../../src/pages/ClimateActionReport-Kanpur.js" /* webpackChunkName: "component---src-pages-climate-action-report-kanpur-js" */),
  "component---src-pages-climate-action-report-lucknow-js": () => import("./../../../src/pages/ClimateActionReport-Lucknow.js" /* webpackChunkName: "component---src-pages-climate-action-report-lucknow-js" */),
  "component---src-pages-climate-action-report-neom-js": () => import("./../../../src/pages/ClimateActionReport-Neom.js" /* webpackChunkName: "component---src-pages-climate-action-report-neom-js" */),
  "component---src-pages-climate-action-report-tsemrs-js": () => import("./../../../src/pages/ClimateActionReport-TSEMRS.js" /* webpackChunkName: "component---src-pages-climate-action-report-tsemrs-js" */),
  "component---src-pages-climate-action-report-tswreis-js": () => import("./../../../src/pages/ClimateActionReport-TSWREIS.js" /* webpackChunkName: "component---src-pages-climate-action-report-tswreis-js" */),
  "component---src-pages-climate-action-report-wolfcreek-js": () => import("./../../../src/pages/ClimateActionReport-wolfcreek.js" /* webpackChunkName: "component---src-pages-climate-action-report-wolfcreek-js" */),
  "component---src-pages-climate-action-report-yadagirigutta-js": () => import("./../../../src/pages/ClimateActionReport-Yadagirigutta.js" /* webpackChunkName: "component---src-pages-climate-action-report-yadagirigutta-js" */),
  "component---src-pages-climate-action-report-zaheerabad-js": () => import("./../../../src/pages/ClimateActionReport-Zaheerabad.js" /* webpackChunkName: "component---src-pages-climate-action-report-zaheerabad-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-our-goal-js": () => import("./../../../src/pages/our-goal.js" /* webpackChunkName: "component---src-pages-our-goal-js" */),
  "component---src-pages-our-impact-js": () => import("./../../../src/pages/our-impact.js" /* webpackChunkName: "component---src-pages-our-impact-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-person-detail-js": () => import("./../../../src/templates/person-detail.js" /* webpackChunkName: "component---src-templates-person-detail-js" */)
}

